import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Container } from '../../../shared/styles/utils';
import { mobile, desktop } from '../../../shared/styles/media-queries';

const FamiliaLink = () => (
  <Container>
    <StyledLink to="/timblackfamilia" data-analytics-selector="btn-banner">
      TIM Black Familia
    </StyledLink>
  </Container>
);

const StyledLink = styled(Link)`
  display: block;
  text-indent: -99999px;

  ${mobile} {
    background: url('/images/familia-link/familia-mobile.jpg');
    height: 480px;
    margin: 0 auto 40px auto;
    width: 320px;
  }

  ${desktop} {
    background: url('/images/familia-link/familia-desktop.jpg');
    height: 200px;
    margin: 0 0 40px 0;
    width: 100%;
  }
`;

export default FamiliaLink;
